<template>
  <ion-content>
    <ion-header mode="md" class="header">
      <ion-toolbar class="modal-toolbar" mode="md">
        <ion-title>Segnala {{ nodeName }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="close">
            <ion-icon slot="icon-only" :icon="closeSharp"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <div class="ion-padding-start ion-padding-end ion-padding-bottom">
      <ion-item fill="outline" class="ion-margin-top">
        <ion-label position="floating">Motivo della segnalazione</ion-label>
        <ion-select id="selectMotivation" v-model="report_model.motivation" cancel-text="Annulla">
          <ion-select-option value="annoys_me">Mi da fastidio</ion-select-option>
          <ion-select-option value="copyright_infringement">Viola il copyright</ion-select-option>
          <ion-select-option value="spam">È spam</ion-select-option>
          <ion-select-option value="inappropriate">
            <div>Non dovrebbe essere su Dantebus</div>
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item fill="outline" class="ion-margin-top">
        <ion-label position="floating">Descrivi il problema <small>(facoltativo)</small></ion-label>
        <ion-textarea v-model="report_model.description" rows="13" />
      </ion-item>
    </div>
    <ion-footer>
      <ion-toolbar mode="md">
        <ion-buttons slot="end">
          <ion-button color="primary" @click="store" :disabled="saving">
            <ion-spinner v-if="saving" />
            <span v-else>Invia</span>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  </ion-content>
</template>

<script>
import { closeSharp } from "ionicons/icons";
import appInterface from "../../mixins/appInterface";
import axios from "../../boot/axios";
export default {
  name: "ReportNode",
  props: {
    nodeName: {
      type: String,
      default: "",
    },
    nodeId: {
      type: Number,
      required: true,
    },
    close: {
      type: Function
    }
  },
  mixins: [appInterface],
  setup: () => ({ closeSharp }),
  data: () => ({
    saving: false,
    isOpen: false,
    report_model: {
      node_id: null,
      motivation: null,
      description: null,
    },
  }),
  mounted() {
    this.report_model.node_id = this.nodeId;
    this.report_model.motivation = null;
    this.report_model.description = null;
  },
  methods: {
    /*close() {
      const elements = document.getElementsByClassName("reportModal");
      for (let i in elements) {
        if (elements[i].dismiss) {
          elements[i].dismiss();
        }
      }
    },*/
    onClick() {
      this.isOpen = true;
    },
    async store() {
      if (!this.report_model.motivation) {
        this.showToast("Seleziona un motivo per continuare");
        return;
      }

      const response = await axios.post("/report", this.report_model);
      if (response.data.status === true) {
        this.showToast("Segnalazione inviata con successo", {
          color: "success",
        });
        this.close();
      } else {
        this.showToast("Si è verificato un errore inaspettato. Riprova");
      }
    },
  },
};
</script>
