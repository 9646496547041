import axios from 'axios'
import { Storage } from '@ionic/storage';
const store = new Storage();

axios.interceptors.request.use(
    async (config) => {
        await store.create();
        let jwt = await store.get('dantebus-jwt');
 
        if (jwt) {
            config.headers['Authorization'] = `Bearer ${jwt.token}`;
        }
 
        return config;
    },
 
    (error) => {
        return Promise.reject(error);
    }
);
export default axios;