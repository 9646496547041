export default {
  methods: {
    getNotificationContent(fromUserName, action, subject) {
      console.log('getNotificationContent', subject)
      const name =
        subject !== null ? this.getNameNode(action, subject) : undefined
      switch (action) {
        case 'new_follower':
          return "L'utente <b>" + fromUserName + '</b> ha iniziato a seguirti'
        case 'subscription_request':
          return (
            "L'utente <b>" +
            fromUserName +
            "</b> ha richiesto l'adesione al gruppo <b>" +
            subject.name +
            '</b>'
          )
        case 'comment':
          return (
            "L'utente <b>" +
            fromUserName +
            '</b> ' +
            (name !== undefined
              ? ' ha commentato un tuo ' + name
              : 'ha scritto un commento')
          )
        case 'like':
          return (
            "L'utente <b>" +
            fromUserName +
            '</b> ha messo mi piace ' +
            (name !== undefined ? 'a un tuo ' + name : '')
          )
        case 'new_post':
          return (
            "L'utente <b>" +
            fromUserName +
            '</b> ha pubblicato un nuovo post '
          )
        case 'comment_tag':
          return (
            "L'utente <b>" +
            fromUserName +
            '</b> ti ha taggato in un commento ' +
            (name !== undefined ? 'di un ' + name : '')
          )
        case 'new_subscription':
          return (
            "L'utente <b>" +
            fromUserName +
            '</b> si è iscritto al gruppo ' +
            (subject.name ? '<b>' + subject.name + '</b>' : '')
          )
        case 'reply':
          return (
            "L'utente <b>" +
            fromUserName +
            '</b> ' +
            (name !== undefined
              ? ' ha risposto a un tuo ' + name
              : 'ha scritto un commento')
          )
        case 'group_request_accepted':
          return (
              "La tua richiesta di adesione al gruppo <b>"+subject.name+"</b> è stata accettata"
          )
        case 'group_new_permission_admin':
          return (
              "Ora sei admin del gruppo <b>" + subject.name + "</b>"
          )
        case 'group_new_permission_mod':
          return (
              "Ora sei moderatore del gruppo <b>" + subject.name + "</b>"
          )
        case 'group_unban':
          return (
              "Puoi di nuovo accedere al gruppo <b>" + subject.name + "</b>"
          )
        case 'group_ban':
          return (
              "Hai ricevuto un ban dal gruppo <b>" + subject.name + "</b>. Non potrai più accederci."
          )
        default:
          break
      }
    },
    getNameNode(action, subject) {
      var node = subject.relationship ? subject.relationship.name : subject.name;
      console.log('subject', subject);
      switch (action) {
        case 'like':
        case 'reply':
        case 'group_request_accepted':
        case 'group_new_permission_admin':
        case 'group_new_permission_mod':
        case 'group_unban':
        case 'group_ban':
          node = subject.name
          break
        case 'comment':
          node = subject.relationship.name
          break
        default:
          break
      }

      switch (node) {
        case 'PostNode':
          return 'post'
        case 'GroupNode':
          return 'gruppo'
        case 'CommentNode':
          return 'commento'
        default:
          break
      }
    },
    getRouter(action, item) {
      console.log(action, item)
      const rottaChat = '/chat/' + item.from_user.id;
      const rottaPost = '/post/' + item.subject.id;
      const rottaGruppi = '/group/' + item.subject.id;
      const rottaGruppiRequests = '/group/' + item.subject.id + '/members/unconfirmed';
      const rottaProfilo = '/profile/' + item.from_user.id;
      const rottaCommenti =
        item.subject.name === 'CommentNode'
          ? '/post/' +
            item.subject.relationship.id +
            '/' +
            item.subject.id +
            '?t=' +
            new Date().getTime()
          : rottaPost;

      let classes = {
        subscription_request: rottaGruppiRequests,
        comment: rottaCommenti,
        like: rottaCommenti,
        new_post: rottaPost,
        comment_tag: rottaCommenti,
        new_subscription: rottaGruppi,
        group_request_accepted: rottaGruppi,
        group_new_permission_admin: rottaGruppi,
        group_new_permission_mod: rottaGruppi,
        group_unban: rottaGruppi,
        group_ban: rottaGruppi,
        new_follower: rottaProfilo,
        reply: rottaCommenti,
        chat_message:rottaChat
      }
      if (action in classes) return classes[action]
    }
  },
}
