import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import AudioVisual from 'vue-audio-visual';
import VuePlyr from 'vue-plyr/dist/vue-plyr.polyfilled.esm';
import CardMessage from './layouts/CardMessage.vue';

import {
  IonAvatar,
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonicVue,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSkeletonText,
  IonRow,
  IonGrid,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonPopover,
  IonRouterOutlet,
  IonApp,
  IonBackButton,
  IonCol,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonFooter,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonRadio,
  IonRadioGroup,
  IonListHeader,
  IonChip,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonReorderGroup,
  IonReorder,
  IonSearchbar,
  IonToggle,
  IonPage,
  IonCardTitle,
  IonTextarea,
  IonCheckbox,
  IonThumbnail,
  IonProgressBar,
  isPlatform,
  IonRefresher,
  IonRefresherContent,
  IonCardSubtitle,
  IonRippleEffect,
} from '@ionic/vue';
import VueVirtualScroller from 'vue-virtual-scroller';
import WaveSurferVue from 'wavesurfer.js-vue/src/WaveSurferVue';
import VueHorizontal from 'vue-horizontal';

import 'vue-plyr/dist/vue-plyr.css';

/* import virtual Scroll */
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

/* Swiper */
import '@ionic/vue/css/ionic-swiper.css';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation, Zoom } from 'swiper';
import { SwiperSlide } from 'swiper/vue';

// install Swiper modules
SwiperCore.use([Pagination, Navigation, Zoom]);

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/lazy';
import 'swiper/css/pagination';
import 'swiper/css/zoom';

/* Axios */
import axios from 'axios';

axios.defaults.baseURL = 'https://social-api.dantebus.com';
//axios.defaults.baseURL = 'http://127.0.0.1:3333';

/* Websockets */
/* eslint-disable no-unused-vars */
import regeneratorRuntime from 'regenerator-runtime';
/* eslint-enable no-unused-vars */
import Ws from '@adonisjs/websocket-client/index';

let ws;
try {
  if (window.location && window.location.protocol === 'https:') {
    ws = Ws('wss://social-ws.dantebus.com');
  } else {
    ws = Ws('ws://social-ws.dantebus.com');
  }
} catch (error) {
  console.log(error);
}

/* Inizializzazione Firebase */
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyBylMVs5PfUJNVA7U9HS3EaMpEAC7318fc',
  authDomain: 'dantebus-e51bb.firebaseapp.com',
  databaseURL: 'https://dantebus-e51bb.firebaseio.com',
  projectId: 'dantebus-e51bb',
  storageBucket: 'dantebus-e51bb.appspot.com',
  messagingSenderId: '1063765107877',
  appId: '1:1063765107877:web:514e89ba57bc4be0ce994c',
};

try {
  initializeApp(firebaseConfig);
} catch (error) {
  console.log('InitializaAppFirebase', error);
}

console.log('main', navigator);
if ('serviceWorker' in navigator && 'PushManager' in window) {
  navigator.serviceWorker
    .register('firebase-messaging-sw.js')
    .then((register) => {
      console.log(register);
    });
}

/* Vuex */
import store from './store';

import timeago from 'vue-timeago3';

const timeagoOptions = {
  converterOptions: {
    includeSeconds: false,
  },
};

import mitt from 'mitt';
import { EditorContent } from '@tiptap/vue-3';
import ReportNode from './components/Popups/ReportNode';
import { createGtm } from '@gtm-support/vue-gtm';

const emitter = mitt();

import VueZoomer from 'vue-zoomer';

import Lightgallery from 'lightgallery/vue';

import VueDOMPurifyHTML from 'vue-dompurify-html';
import Vue3Lottie from 'vue3-lottie';
import 'vue3-lottie/dist/style.css';

const app = createApp(App)
  .use(store)
  .use(IonicVue, {
    mode: 'md',
  })
  .use(VueHorizontal)
  .use(VueVirtualScroller)
  .use(router)
  .use(timeago, timeagoOptions)
  .use(AudioVisual)
  .use(VueZoomer)
  .use(VueDOMPurifyHTML)
  .use(Vue3Lottie, { name: 'lottie-animation' })
  .use(
    createGtm({
      id: 'GTM-MVRSKNR',
      enabled: false,
      loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    })
  );

app.component('light-gallery', Lightgallery);
app.component('ion-app', IonApp);
app.component('ion-back-button', IonBackButton);
app.component('ion-page', IonPage);
app.component('ion-item', IonItem);
app.component('ion-button', IonButton);
app.component('ion-label', IonLabel);
app.component('ion-avatar', IonAvatar);
app.component('ion-modal', IonModal);
app.component('ion-list', IonList);
app.component('ion-img', IonImg);
app.component('ion-buttons', IonButtons);
app.component('ion-badge', IonBadge);
app.component('ion-icon', IonIcon);
app.component('ion-content', IonContent);
app.component('ion-skeleton-text', IonSkeletonText);
app.component('ion-row', IonRow);
app.component('ion-grid', IonGrid);
app.component('ion-segment', IonSegment);
app.component('ion-segment-button', IonSegmentButton);
app.component('ion-spinner', IonSpinner);
app.component('ion-popover', IonPopover);
app.component('ion-router-outlet', IonRouterOutlet);
app.component('ion-col', IonCol);
app.component('ion-header', IonHeader);
app.component('ion-title', IonTitle);
app.component('ion-toolbar', IonToolbar);
app.component('ion-footer', IonFooter);
app.component('ion-input', IonInput);
app.component('ion-textarea', IonTextarea);
app.component('ion-select', IonSelect);
app.component('ion-select-option', IonSelectOption);
app.component('ion-radio', IonRadio);
app.component('ion-radio-group', IonRadioGroup);
app.component('ion-list-header', IonListHeader);
app.component('ion-chip', IonChip);
app.component('ion-infinite-scroll', IonInfiniteScroll);
app.component('ion-infinite-scroll-content', IonInfiniteScrollContent);
app.component('ion-item-sliding', IonItemSliding);
app.component('ion-item-options', IonItemOptions);
app.component('ion-item-option', IonItemOption);
app.component('ion-card', IonCard);
app.component('ion-card-header', IonCardHeader);
app.component('ion-card-content', IonCardContent);
app.component('ion-reorder-group', IonReorderGroup);
app.component('ion-reorder', IonReorder);
app.component('ion-searchbar', IonSearchbar);
app.component('ion-toggle', IonToggle);
app.component('swiper-slide', SwiperSlide);
app.component('ion-card-title', IonCardTitle);
app.component('ion-card-subtitle', IonCardSubtitle);
app.component('ion-checkbox', IonCheckbox);
app.component('ion-thumbnail', IonThumbnail);
app.component('ion-progress-bar', IonProgressBar);
app.component('ion-refresher', IonRefresher);
app.component('ion-refresher-content', IonRefresherContent);
app.component('ion-ripple-effect', IonRippleEffect);

//Componenti Custom
app.component('editor-content', EditorContent);
app.component('report-node', ReportNode);
app.component('WaveSurferVue', WaveSurferVue);
app.component('VuePlyr', VuePlyr);
app.component('card-message', CardMessage);

app.config.globalProperties.emitter = emitter;

app.config.globalProperties.$ws = ws;

import lightGalleryModule from 'lightgallery';
import lgZoom from 'lightgallery/plugins/zoom';
import VueSecureHTML from 'vue-html-secure';

app.config.globalProperties.$safeHTML = VueSecureHTML.safeHTML;
app.config.globalProperties.$escapeHTML = VueSecureHTML.escapeHTML;
app.config.globalProperties.$removeHTML = VueSecureHTML.removeHTML;

app.config.globalProperties.$lightGallery = lightGalleryModule;
app.config.globalProperties.$lgZoom = lgZoom;
//let global_ws = ws;
//console.log('connecting', global_ws.connect());
/*global_ws.on('open', () => {
  console.log('GLOBAL_WS OPEN')
  global_ws.on('reconnect', (data) => {
    console.log('RICONNESSIONE DEL SERVER SOCKET', data);
  })
  global_ws.on('error', (data) => {
    console.log('ERRORE DEL SERVER SOCKET', data);
  })
  global_ws.on('close', (data) => {
    console.log('CHIUSURA DEL SERVER SOCKET', data);
  })
  app.config.globalProperties.$ws = global_ws;
  store.$ws = global_ws
});
global_ws.on('error', (error) => {
  console.log('GLOBAL WS ERROR', error);
})*/

if (isPlatform('android')) {
  try {
    app.mount('#app');
  } catch (error) {
    console.log('response mount app android', error);
  }
} else {
  router.isReady().then(async () => {
    try {
      app.mount('#app');
    } catch (error) {
      console.log('response router isReady', error);
    }
  });
}

console.log(process.env.NODE_ENV);
if (process.env.NODE_ENV === 'production') {
  console.log = function () {};
}

window.onerror = (message, url, lineNo, columnNo, error) => {
  const string = message.toLowerCase();

  // ResizeObserver loop limit exceeded
  // we can safely ignore this one
  // source: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
  if (string.indexOf('resizeobserver') > -1) {
    // do not throw this
    // When the function returns true, this prevents the firing of the default event handler.
    // source: https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror
    return true;
  }

  const errorObj = {
    type: 'js.error',
    error: {
      message,
      url,
      line: lineNo,
      col: columnNo,
      errorObject: JSON.stringify(error),
    },
  };

  throw errorObj;
};
