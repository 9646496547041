import { findIndex } from 'lodash-es';
export default {
  //state
  setUser(state, user) {
    state.user = user;
  },
  setUserHasActiveApp(state, user_has_active_app) {
    state.user_has_active_app = user_has_active_app;
  },
  setClaims(state, claims) {
    state.claims = claims;
  },
  setUserEmail(state, email) {
    state.user.email = email;
  },
  setEmailVerified(state, emailVerified) {
    state.emailVerified = emailVerified;
  },
  setNode(state, node) {
    state.node = node;
  },
  setNodeProperty(state, { property_name, property_value }) {
    state.node[property_name] = property_value;
  },
  setVerificationEmailSentAt(state) {
    state.verificationEmailSentAt = new Date();
  },
  setImage(state, { type, path }) {
    console.log('state', state.user);
    state.node[type + '_path'] = path;
    console.log('state', state.user);
  },
  setProfile(state, profile) {
    state.profile = profile;
    state.node.profile = profile;
  },
  setCounts(state, counts) {
    state.counts = counts;
  },
  setBlockedUsersIds(state, users) {
    state.blockedUsersIds = users;
  },
  setChatUnread(state, value) {
    state.chatUnread = value;
  },
  setIsFirstLogin(state, value) {
    state.isFirstLogin = value;
  },
  pushBlockedUserId(state, id) {
    state.blockedUsersIds.push(id);
  },
  removeBlockedUserId(state, id) {
    const index = state.blockedUsersIds.indexOf(id);
    if (index < 0) return false;
    state.blockedUsersIds.splice(index, 1);
  },
  resetState(state) {
    for (let i in state) {
      state[i] = null;
    }
  },
  setForgotPasswordData(state, { email, token }) {
    if (email != undefined) {
      state.forgotPasswordData.email = email;
    }
    if (token != undefined) {
      state.forgotPasswordData.token = token;
    }
  },
  resetForgotPasswordData(state) {
    state.forgotPasswordData = { toke: null, email: null };
  },

  /**
   * Aggiornamento Conteggio
   * @param {*} state stato
   * @param {*} {action,count} action = azione increase, count = nome del tipo di conteggio
   */
  updateSingleCount(state, { action, count }) {
    if (action === 'increase') {
      state.counts[count] = state.counts[count] + 1;
    } else if (action === 'all') {
      state.counts[count] = 0;
    } else {
      state.counts[count] = state.counts[count] - 1;
    }
    console.log(state.counts[count]);
  },

  updateSuggestion(state, { users }) {
    state.suggestions = users;
  },

  updateNotificationSettings(state, { settings }) {
    state.node.notification_settings = JSON.stringify(settings);
  },

  addFollowedUserId(state, id) {
    state.followedUsersIds.push(id);
  },
  removeFollowedUserId(state, id) {
    const index = findIndex(state.followedUsersIds, (o) => o === id);
    if (index >= 0) state.followedUsersIds.unshift(index, 1);
  },

  updateNotificationsDisabled(state, { settings }) {
    state.node.notifications_disabled = settings;
  },

  updateInternalNotificationsData(state, object) {
    state.internalNotificationsData = object;
  },
  updateInternalNotificationClaim(state, { key, value }) {
    if (state.claims && state.claims.internalNotifications) {
      state.claims.internalNotifications[key] = value;
    } else {
      //aggiungo internalNotifications
      state.claims = {
        ...state.claims,
        internalNotifications: {
          [key]: value,
        },
      };
    }
  },
};
