import { createRouter, createWebHistory } from '@ionic/vue-router';
import store from '../store';
import { loadingController } from '@ionic/vue';

const routes = [
  {
    path: '/',
    redirect: '/home',
    component: () => import('../layouts/LoggedInLayout.vue'),
    children: [
      {
        path: 'wall',
        name: 'wall',
        component: () =>
          import(/* webpackChunkName: "lazy-view-wall" */ '../pages/Wall.vue'),
        meta: {
          authRequired: true,
        },
      },
      {
        path: 'dantebus-edizioni/form',
        name: 'dantebus-edizioni-form',
        component: () =>
          import(
            /* webpackChunkName: "lazy-view-wall" */ '../pages/DantebuEdizioni/FormManoscritto.vue'
          ),
        meta: {
          authRequired: true,
        },
      },
      {
        path: 'profile',
        name: 'profile',
        component: () =>
          import(
            /* webpackChunkName: "lazy-view-profile" */ '../pages/Profile.vue'
          ),
        meta: {
          authRequired: true,
        },
        children: [
          {
            path: ':id',
            name: 'postProfile',
            component: () =>
              import(
                /* webpackChunkName: "lazy-view-postProfile" */ '../pages/Profile/PostProfile.vue'
              ),
            meta: {
              authRequired: true,
            },
          },
          {
            path: ':id/portfolio',
            name: 'portfolio',
            component: () =>
              import(
                /* webpackChunkName: "lazy-view-currentGroup" */ '../pages/Profile/Portfolio.vue'
              ),
            meta: {
              authRequired: true,
            },
          },
          {
            path: ':id/info',
            name: 'infoProfile',
            component: () =>
              import(
                /* webpackChunkName: "lazy-view-infoProfile" */ '../pages/Profile/InfoProfile.vue'
              ),
            meta: {
              authRequired: true,
            },
          },
          {
            path: ':id/adv',
            name: 'adv',
            component: () =>
              import(
                /* webpackChunkName: "lazy-view-advProfile" */ '../pages/Profile/AdvertiserProfile.vue'
              ),
            meta: {
              authRequired: true,
            },
          },
          {
            path: ':id/groups',
            name: 'currentGroup',
            component: () =>
              import(
                /* webpackChunkName: "lazy-view-currentGroup" */ '../pages/Profile/GroupProfile.vue'
              ),
            meta: {
              authRequired: true,
            },
          },
          {
            path: ':id/bookmarked',
            name: 'bookmarked',
            component: () =>
              import(
                /* webpackChunkName: "lazy-view-currentGroup" */ '../pages/Profile/Bookmarked.vue'
              ),
            meta: {
              authRequired: true,
            },
          },
        ],
      },
      {
        path: 'chat',
        name: 'chat',
        component: () =>
          import(/* webpackChunkName: "lazy-view-chat" */ '../pages/ChatView'),
        meta: {
          authRequired: true,
        },
        children: [
          {
            path: '',
            name: 'chatList',
            component: () =>
              import(
                /* webpackChunkName: "lazy-view-chat-list" */ '../pages/Chat/ChatList'
              ),
            meta: {
              authRequired: true,
            },
          },
          {
            path: ':user_id',
            name: 'singleChat',
            component: () =>
              import(
                /* webpackChunkName: "lazy-view-single-chat" */ '../pages/Chat/SingleChat'
              ),
            meta: {
              authRequired: true,
            },
          },
        ],
      },
      {
        path: 'post/:id/:comment_id?',
        name: 'singlePost',
        component: () =>
          import(
            /* webpackChunkName: "lazy-view-singlePost" */ '../pages/SinglePost.vue'
          ),
        meta: {
          authRequired: true,
        },
      },
      {
        path: 'search',
        name: 'mobileSearch',
        component: () =>
          import(
            /* webpackChunkName: "lazy-view-mobileSearch" */ '../pages/MobileSearch.vue'
          ),
        meta: {
          authRequired: true,
        },
      },
      {
        path: 'group',
        name: 'singleGroup',
        component: () =>
          import(
            /* webpackChunkName: "lazy-view-singleGroup" */ '../pages/SingleGroup.vue'
          ),
        meta: {
          authRequired: true,
        },
        children: [
          {
            path: ':group_id',
            name: 'singleGroup',
            component: () =>
              import(
                /* webpackChunkName: "lazy-view-c" */ '../pages/Group/PostGroup.vue'
              ),
            meta: {
              authRequired: true,
            },
          },
          {
            path: ':group_id/members/:currentMembersView?',
            name: 'membersGroup',
            component: () =>
              import(
                /* webpackChunkName: "lazy-view-membersGroup" */ '../pages/Group/MembersGroup.vue'
              ),
            meta: {
              authRequired: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../layouts/HomePage'),
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../layouts/Auth'),
    children: [
      {
        path: '',
        name: 'login',
        component: () =>
          import(/* webpackChunkName: "lazy-view-login" */ '../pages/Login'),
      },
      {
        path: 'registration',
        name: 'registration',
        component: () =>
          import(
            /* webpackChunkName: "lazy-view-registration" */ '../pages/Registration'
          ),
      },
      {
        path: 'forgotPassword',
        name: 'forgotPassword',
        component: () =>
          import(
            /* webpackChunkName: "lazy-view-forgotPassword" */ '../pages/ForgotPassword/SendEmail'
          ),
      },
      {
        path: 'checkToken',
        name: 'checkToken',
        component: () =>
          import(
            /* webpackChunkName: "lazy-view-checkToken" */ '../pages/ForgotPassword/ValidateCode'
          ),
      },
      {
        path: 'newPassword',
        name: 'newPassword',
        component: () =>
          import(
            /* webpackChunkName: "lazy-view-newPassword" */ '../pages/ForgotPassword/UpdatePassword'
          ),
      },
    ],
  },
  { path: '/:pathMatch(.*)*', redirect: '/wall' },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

//Controllo di autenticazione
let loading;
router.beforeEach(async (to, from, next) => {
  loading = await loadingController.create({
    message: 'Caricamento in corso...',
    cssClass: 'alert-css-class',
    mode: 'md',
  });

  console.log('to', to);
  console.log('from', from);
  if (to.redirectedFrom && to.redirectedFrom.fullPath.startsWith('/me/info')) {
    await loading.present();
    const id = to.redirectedFrom.params.pathMatch[2];
    const token = to.redirectedFrom.params.pathMatch[3];
    if (token && id) {
      const decoded = JSON.parse(decodeURIComponent(token));
      console.log('decoded', decoded);
      const save_token = await store.dispatch('user/saveStoredToken', {
        token: decoded,
      });
      console.log('save_token', save_token);
      await loading.dismiss();
      const auth_status = await store.dispatch('user/checkStoredToken');
      if (auth_status)
        return (window.location.href = '/profile/' + id + '/info');
      return next('/home');
    }
    return next();
  } else if (to.meta && to.meta.authRequired === true) {
    const current_user = store.getters['user/user'];
    if (!current_user) {
      await loading.present();
      const auth_status = await store.dispatch('user/checkStoredToken');
      if (auth_status) return next();
      if (to.name === 'auth') return next();
      if (window.localStorage) {
        window.localStorage.setItem('authRedirect', window.location.href);
      }
      return next('/home');
    }
    return next();
  } else if (from.name === undefined) {
    const current_user = store.getters['user/user'];
    if (current_user) return next('/wall');
    else {
      await loading.present();
      const auth_status = await store.dispatch('user/checkStoredToken');
      if (auth_status) {
        next('/wall');
        await loading.dismiss();
        return;
      }
    }
  }

  next();
});
router.afterEach(async () => {
  if (loading) {
    await loading.dismiss();
    const loading_element = document.querySelector('ion-loading');
    if (loading_element) loading_element.remove();
  }
});

export default router;
