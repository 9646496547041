<template>
  <ion-app style="touch-action: none;">
    <div v-if="true">
      <ion-router-outlet animated="false" />
      <template v-if="emailVerified === false">
        <div class="email-verification-footer w500-up">
          <span>Il tuo indirizzo email non è verificato</span>
          <ion-button class="ion-margin-start" color="white" fill="outline" size="small"
            @click="sendVerificationEmail()" :disabled="emailSending || countdownVerification !== null">
            <template v-if="countdownVerification !== null">Puoi riprovare tra {{ countdownVerification }}
              secondi</template>
            <template v-else>
              <ion-spinner color="white" v-if="emailSending" name="dots" />
              <span v-else>Invia la mail di verifica</span>
            </template>
          </ion-button>
        </div>
        <div class="email-verification-footer w500-down">
          <ion-button color="white" fill="outline" size="small" @click="sendVerificationEmail()"
            :disabled="emailSending || countdownVerification !== null">
            <template v-if="countdownVerification !== null">Puoi riprovare tra {{ countdownVerification }}
              secondi</template>
            <template v-else>
              <ion-spinner color="white" v-if="emailSending" name="dots" />
              <span v-else>Verifica la mia email</span>
            </template>
          </ion-button>
        </div>
      </template>
    </div>
    <server-maintenance v-else></server-maintenance>
    <install-app v-if="!isNative && !isDesktop" />
  </ion-app>
</template>

<script>
import { getMessaging, onMessage, isSupported } from "@firebase/messaging";
import appInterface from "./mixins/appInterface";
import { isPlatform, popoverController } from "@ionic/core";
import { StatusBar } from "@capacitor/status-bar";
import notificationInterface from "./mixins/notificationInterface";
import { App } from "@capacitor/app";
import { getApp } from "firebase/app";
import ServerMaintenance from "./components/ServerMaintenance";
import {
  fetchAndActivate,
  getRemoteConfig,
  getValue,
} from "firebase/remote-config";
import { actionSheetController, alertController, loadingController, modalController } from "@ionic/vue";
import { SplashScreen } from "@aparajita/capacitor-splash-screen";
import InstallApp from './components/Popups/InstallApp.vue';
import {
  AppTrackingTransparency,
} from 'capacitor-plugin-app-tracking-transparency';
import { setBackForwardNavigationGestures } from "capacitor-plugin-ios-webview-configurator";
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { Storage } from '@ionic/storage'
import { getPreferencesObject, setPreferencesObject } from "./helpers/preferencesHelper";
import { PushNotifications } from "@capacitor/push-notifications";
import axios from "axios";
const store = new Storage()
CapacitorUpdater.notifyAppReady()

export default {
  name: "App",
  mixins: [appInterface, notificationInterface],
  data: () => ({
    emailSending: false,
    checkEmailInterval: null,
    countdownVerification: null,
    maintenance: false,
  }),
  components: { ServerMaintenance, InstallApp },
  watch: {
    user(value) {
      if (value !== null) {
        this.loadSuggestions();
      }
    },
    emailVerified(value) {
      if (value && this.checkEmailInterval) {
        clearInterval(this.checkEmailInterval);
      }
    },
  },
  computed: {
    emailVerified() {
      if (!this.user) return null;
      return this.$store.getters["user/emailVerified"];
    },
  },
  async mounted() {
    await store.create();
    if (isPlatform("capacitor")) {

      if (isPlatform('ios')) {
        setBackForwardNavigationGestures(true);

        const response = await AppTrackingTransparency.getStatus();
        console.log(response);
        if (response.status === 'notDetermined') {
          const response = await AppTrackingTransparency.requestPermission();
          console.log(response);
        }
      }

      try {
        await SplashScreen.hide({
          delay: 0,
          fadeOutDuration: 500,
        })
        if (window.location.hostname === "localhost") {
          const currentVersion = await getPreferencesObject('current_code_version');
          console.warn('Stato APP', currentVersion)
          console.warn('Stato APP', currentVersion != null ? currentVersion : 'builtin')
          await this.checkNewVersionAPP(currentVersion != null ? currentVersion : 'builtin', 'mounted');
        }
      } catch (error) {
        console.log(error)
      }

    } else if (isPlatform('desktop') && this.$gtm) {
      this.$gtm.enable(true)
    }


    if (this.isNative) {
      try {
        PushNotifications.removeAllDeliveredNotifications();
      } catch (error) {
        console.error(error)
      }
    }

    const path = this.$router.currentRoute.value.path;
    if (this.user !== null) {
      if (this.isNative) {
        if (isPlatform("android")) {
          try {
            await StatusBar.setOverlaysWebView({ overlay: false });
          } catch (error) {
            console.log(error);
          }
        }
      } else {
        try {
          if (isSupported()) {
            const messaging = getMessaging();
            onMessage(messaging, async (payload) => {
              console.log("Message received. ", payload);
              const body = JSON.parse(payload.data.data);
              console.log(body);
              /*const result = await this.$store.dispatch(
                "userNotification/newPushNotification",
                { notification: body.notification }
              );*/
              //console.log(result);
            });
          }
        } catch (error) {
          console.log('Firebase Messaging', error)
        }
      }

      if (typeof BroadcastChannel !== undefined) {
        try {
          const channel = new BroadcastChannel("sw-messages");
          channel.addEventListener("message", async (event) => {
            console.log("Received", event.data);
            const payload = event.data;
            console.log("Message received. ", payload);
            const body = JSON.parse(payload.data.data);
            console.log(body);
          });
        } catch (e) {
          console.log('Err broadcastchannel', e);
        }
      }

      if (path === "/auth") {
        this.pushTo("/wall");
      } else {
        this.loadSuggestions();
      }
    }
    this.genericLoading = false;

    if (this.isNative) {

      App.addListener("backButton", async (data) => {
        const lightGalleryContainer = document.getElementsByClassName('generic-image-gallery-class');
        const actionSheet = await actionSheetController.getTop();
        const modalOpened = await modalController.getTop();
        const popover = await popoverController.getTop();
        if (lightGalleryContainer.length > 0) {
          while (lightGalleryContainer.length > 0) {
            lightGalleryContainer[0].parentNode.removeChild(lightGalleryContainer[0]);
          }
        } else if (actionSheet) {
          await actionSheet.dismiss();
        } else if (modalOpened) {
          await modalOpened.dismiss();
        } else if (popover) {
          await popover.dismiss();
        } else if (!data.canGoBack) {
          this.exitApp();
        }
      });

      //Attivo o disattivo le notifiche push in base allo stato
      App.addListener('appStateChange', async ({ isActive }) => {
        console.log('App state changed. Is active?', isActive);
        if (isActive) {
          if (window.location.hostname === "localhost") {
            const currentVersion = await getPreferencesObject('current_code_version');
            console.warn('Stato APP', currentVersion)
            console.warn('Stato APP', currentVersion != null ? currentVersion : 'builtin')
            await this.checkNewVersionAPP(currentVersion != null ? currentVersion : 'builtin', 'appStateChange');
          }

          let jwt = await store.get('dantebus-jwt');
          //Controllo se ho il token jwt
          if (jwt !== null && jwt !== undefined) {
            this.$store.dispatch('user/checkStoredToken').then((auth_check) => {
              console.log('checkStoredToken', auth_check)
              if (auth_check === false) {
                console.log('Cancello il token');
                store.create().then(() => store.remove('dantebus-jwt').then(() => window.location.href = '/home').catch((error) => console.log(error)));
              }
            });
          }


          //Rimuovo Listener Notification
          await this.removeNotificatonListener()
        } else {
          //Caricamento Listener Notification
          await this.addNotificationListeners();
        }
      });

      App.addListener('appUrlOpen', function (event) {
        // Example url: https://social.dantebus.com/post/ecc....
        console.log('Path', event);
        var slug = "";
        if (event.url.startsWith('com.dantebus://')) {
          slug = event.url.split('com.dantebus://').pop();
        }
        if (event.url.startsWith('com.dantebus.app://')) {
          slug = event.url.split('com.dantebus.app://').pop();
        }
        if (event.url.startsWith('https://social.dantebus.com/')) {
          slug = event.url.split('https://social.dantebus.com/').pop();
        }
        console.log('Path', slug);
        try {
          if (slug !== '') {
            window.location = slug;
          } else {
            window.open(event.url)
          }
        } catch (error) {
          console.log('Non posso aprire il link')
        }
      });
    }

    const remoteConfig = getRemoteConfig(getApp());
    remoteConfig.settings.minimumFetchIntervalMillis = 5000; //dopo 5 secondi si può rilanciare il comando
    const response = await fetchAndActivate(remoteConfig);
    console.log("response fetchAndActivate", response);
    const current_version = getValue(
      remoteConfig,
      "current_version"
    ).asNumber();
    const _maintenance = getValue(remoteConfig, "maintenance").asBoolean();
    const request_update = getValue(remoteConfig, "request_update").asBoolean();

    const internalNotificationsData = getValue(remoteConfig, "internalNotifications").asString();
    console.log("current_version", current_version);
    console.log("maintenance", _maintenance);
    console.log("request_update", request_update);
    console.log("internalNotificationsData", internalNotificationsData);

    this.$store.commit('user/updateInternalNotificationsData', JSON.parse(internalNotificationsData))

    this.maintenance = _maintenance;

    if (!this.maintenance && (window.navigator.userAgent.indexOf('DB_APP') >= 0 || window.navigator.userAgent.indexOf('DB_IOS') >= 0)) {
      this.maintenance = true;
    }
    if (_maintenance) {
      console.log("Server in manutenzione");
      return;
    } else {
      console.log("Procedere Normalmente");
    }

    document.querySelector("body").addEventListener("click", (e) => {
      if (e.target.getAttribute("data-type") === "mention") {
        const id = e.target.getAttribute("data-id");
        if (id) {
          this.$router.push("/profile/" + id);
        }
      }
    });

    //Provo a bloccare il pinch to zoom su ios ma non se lo prende
    if (isPlatform('ios')) {
      var lastTouchEnd = 0;
      document.querySelector("body").addEventListener('gesturestart', function (event) {
        var now = (new Date()).getTime();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      }, false);
    }


  },
  methods: {
    //Metodo per get update 
    async checkNewVersionAPP(currentVersion, from) {
      let lastNegativeCheck = await store.get('last_negative_check_date')
      if (lastNegativeCheck != null) {
        console.log("last_negative_check_date", lastNegativeCheck)
        const timeDifference = Math.abs(lastNegativeCheck - new Date().getTime());
        const minuteDifference = Math.floor(timeDifference / 60000); // 1 minuto = 60000 millisecondi
        console.log("controllo min", minuteDifference)
        if (minuteDifference < 2) {
          return;
        }
      }

      const response = await axios.get("https://continuum-system.dantebus.com/api/version/dantebus-social?from=" + from);
      const json = await response.data;
      if (json.status === true) {
        const { version, created_at, zip, changelog } = json.version
        console.log('checkDatiVersione',
          version,
          created_at,
          zip,
          changelog
        )
        var txt_changelog;
        try {
          const _changelog = await axios.get(changelog);
          txt_changelog = await _changelog.data
          console.error('checkNewVersionAPP', currentVersion, version)
        } catch (error) {
          txt_changelog = ""
        }

        if (currentVersion.trim() !== version.trim()) {
          const alert = await alertController.create({
            header: 'Dantebus',
            backdropDismiss: false,
            message: 'E\' disponibile un nuovo aggiornamento.<br>' + txt_changelog,
            buttons: [
              {
                text: 'Ignora',
                cssClass: 'secondaryAlertButton',
                handler: async () => {
                  await alert.dismiss();
                }
              },
              {
                text: 'Aggiorna',
                cssClass: 'primaryAlertButton',
                handler: async () => {
                  await alert.dismiss();
                  await this.downloadUpdate(version, zip)
                }
              },],
            cssClass: 'alert-css-class',
            mode: 'md',
          })
          await alert.present()
        }

        console.log("last_negative_check_date", new Date().getTime())
        //Memorizzo la data e ora nello storage
        await store.set('last_negative_check_date', new Date().getTime())
      }

    },
    async downloadUpdate(version, zip) {
      const loading = await loadingController.create({
        message: 'Aggiornamento in corso...',
        cssClass: 'alert-css-class',
        mode: 'md',
      });
      loading.present();
      const download = await CapacitorUpdater.download({
        version,
        url: zip,
      })
      //Storicizzo la versione scaricata
      await setPreferencesObject('current_code_version', version)
      console.error('versione scaricata', download);
      await CapacitorUpdater.set({ id: download.id });
      await loading.dismiss();
    },

    startCountdown() {
      setTimeout(() => {
        if (this.countdownVerification === null)
          this.countdownVerification = 60;
        this.countdownVerification -= 1;
        if (this.countdownVerification > 0) {
          this.startCountdown();
        } else {
          this.countdownVerification = null;
        }
      }, 1000);
    },
    async sendVerificationEmail() {
      this.emailSending = true;
      const sent = await this.$store.dispatch("user/sendVerificationEmail");
      if (sent === true) {
        this.checkEmailInterval = setInterval(async () => {
          const response = this.$store.dispatch("user/checkEmailVerified");
          if (response === false) clearInterval(this.checkEmailInterval);
        }, 5000);
        this.startCountdown();
        await this.showToast("Email di verifica inviata con successo.", {
          color: "success",
        });
      } else {
        await this.showToast(
          "Si è verificato un errore, riprova tra qualche minuto."
        );
      }
      this.emailSending = false;
    },

  },
};
</script>

<style lang="scss">
.wall {
  width: 600px;
  margin: 5px auto 0;

  @media only screen and (max-width: 768px) {
    width: unset;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.content_header {
  margin-top: calc(35px + env(safe-area-inset-top)) !important;
}

.content-layout {
  margin-top: calc(35px + env(safe-area-inset-top)) !important;
}

.header {
  margin-top: env(safe-area-inset-top);
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

@media only screen and (min-width: 769px) {
  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
}

.input-error {
  animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  animation-iteration-count: 2;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.alert-css-class {
  --background: #ffffff;
  color: #000;
}

.flex-spacer {
  flex-grow: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}

.text-ellipsis {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  min-height: 40px;
}

.d-flex {
  display: flex;
}

.d-col {
  flex-direction: column;
}

.d-row {
  flex-direction: row;
}

.wrap {
  flex-wrap: wrap;
}

.h-100 {
  height: 100%;
}

.d-block {
  display: block;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 1;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: start;
}

.justify-end {
  justify-content: end;
}

.justify-space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: start;
}

.align-end {
  align-items: end;
}

.align-baseline {
  align-items: baseline;
}

.justify-self-start {
  justify-self: flex-start;
}

.ion-item-outlined {
  border-color: #403e39;
  border-width: thin;
  border-style: solid;
  border-radius: 3px;
  border-bottom: 0;
}

.alert-radio-label.sc-ion-alert-md {
  color: #000;
  white-space: pre-wrap;
}

[aria-checked="true"].sc-ion-alert-md .alert-radio-label.sc-ion-alert-md {
  color: #000;
  font-weight: bold;
}

.plyr--audio .plyr__control:hover {
  color: #be202e !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.alert-radio-group.sc-ion-alert-md,
.alert-checkbox-group.sc-ion-alert-md {
  border-top: unset;
  border-bottom: unset;
}

ion-card,
.card {
  background-color: hsl(0, 0%, 100%);
  border-radius: 20px;
  border: 1px solid #eee;
  box-shadow: none;
}

.item-card {
  background-color: hsl(0, 0%, 100%);
  border-radius: 20px;
  margin-bottom: 5px;
  border: 1px solid #eee;
  min-height: 50px;
  display: flex;
  padding-left: 10px;
  flex: 1;
  flex-direction: column;
}

.action-sheet {
  --button-color: "#000000";

  .action-sheet-button {
    color: #000000;
  }
}

.ProseMirror {
  outline: 0px solid transparent;
}



#app_logo {
  max-width: 100%;
  width: 300px;
  display: block;
  margin: 1.5rem auto;
  margin-top: calc(35px + env(safe-area-inset-top));

  @media only screen and (max-width: 280px) {
    max-width: 80%;
  }
}

.email-verification-footer {
  position: fixed;
  bottom: 0;
  z-index: 10;
  width: 100%;
  background: #be202e;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  &.w500-up {
    display: flex;
  }

  &.w500-down {
    display: none;
  }

  @media screen and (max-width: 768px) {
    bottom: calc(56px + env(safe-area-inset-bottom));
    padding: 5px;
  }

  @media screen and (max-width: 500px) {
    &.w500-up {
      display: none;
    }

    &.w500-down {
      display: flex;
    }
  }
}

.position-relative {
  position: relative !important;
}

.unread-badge {
  color: #be202e;
  position: absolute;
  bottom: -10px;
  right: -15px;
  font-size: 32px;
  width: 32px;
  height: 32px;
}

body {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

ion-navbar.toolbar.toolbar-ios.statusbar-padding,
ion-navbar.toolbar-ios ion-title.title-ios,
ion-toolbar.toolbar.toolbar-ios.statusbar-padding,
ion-toolbar.toolbar-ios ion-title.title-ios {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

span[data-type="mention"] {
  cursor: pointer;
}

ion-header ion-toolbar:first-of-type {
  padding-top: 0;
  margin-top: calc(0px + env(safe-area-inset-top));
}

.alert-button.ion-focused.sc-ion-alert-md,
.alert-tappable.ion-focused.sc-ion-alert-md {
  background: #e6e6e6;
}

ion-alert {
  --width: 500px !important;
  --max-width: 500px !important;
}

.content_layout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: calc(50px + env(safe-area-inset-top));
  position: relative;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    margin-top: calc(35px + env(safe-area-inset-top));
  }
}

.auth-header {
  padding-top: env(safe-area-inset-top);
  background-image: url("../public/assets/images/backgrounds/pattern-512-150.png");
  min-height: 40vh;
  position: relative;

  .divider {
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
  }

  .text-container {
    margin-top: 5vh;

    h1,
    p {
      margin: 0;
    }
  }
}

.auth-card {
  padding-bottom: calc(20px + env(safe-area-inset-bottom));

  @media screen and (min-width: 769px) {
    height: 100%;
  }

  .auth_form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    @media screen and (max-width: 768px) {
      min-height: 60vh;
      padding-bottom: calc(20px + env(safe-area-inset-bottom));
    }
  }
}

.fill-height {
  height: 100%;
}

.home-container {
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .home-card {
    width: 50vw;
    margin: 0 auto;
    box-shadow: none;
    border-radius: 20px;
    min-height: 35vh;


    @media screen and (max-width: 1200px) {
      width: 90vw !important;
    }

    &.min-height-set {
      min-height: unset;
      height: 50vh;
    }

    .information-col {
      background-image: url("../public/assets/images/backgrounds/pattern-512-150.png");
      background-repeat: repeat;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;

      .animation-container {
        width: 80%;
      }

      h1 {
        font-size: 2rem;
        margin: 0;
      }

      p {
        font-size: 1rem;
        margin: 0
      }

      &.information-col-alternate {
        flex-direction: column;
      }
    }

    .forms-col {
      color: #000;
    }

    .forms-col-home {
      color: #000;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      h1 {
        margin: 0;
        font-size: 2rem;
      }
    }
  }
}


.generic-image-gallery-class {
  .lg-sub-html {
    overflow: auto;
    max-height: calc(350px + env(safe-area-inset-bottom))
  }

  .lg-toolbar {
    margin-top: calc(20px + env(safe-area-inset-top));
  }
}

//wall e singolo post
.content_post {
  width: 600px;

  @media only screen and (max-width: 768px) {
    width: calc(100vw - 5px);
    margin: 0 auto;
  }
}

.content_influencers {
  width: 600px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
  }
}

//Pulsanti upload avatar e copertina

.premium-badge-wrapper {
  position: absolute;
  bottom: -20px;
  right: 0;
  z-index: 10;
  max-width: 100%;
  display: flex;
  justify-content: flex-end;


  .circle-button::part(native) {
    border-radius: 50%;
    width: 40px;
    padding: 0px;
    height: 40px;
  }
}

.premium-badge-icon-wrapper {
  position: absolute;
  right: 5px;
  top: 45%;
  z-index: 10;
  max-width: 100%;
  display: flex;
  justify-content: flex-end;


  .circle-button::part(native) {
    border-radius: 50%;
    width: 15px;
    padding: 0px;
    height: 15px;
  }
}

.premium-badge-icon-bar-wrapper {
  position: absolute;
  right: 0px;
  top: 50%;
  z-index: 10;
  max-width: 100%;
  display: flex;
  justify-content: flex-end;


  .circle-button::part(native) {
    border-radius: 50%;
    width: 15px;
    padding: 0px;
    height: 15px;
  }
}

.badge-icon-wrapper {
  position: absolute;
  left: 5px;
  top: 45%;
  z-index: 10;
  max-width: 100%;
  display: flex;
  justify-content: flex-start;


  .circle-button::part(native) {
    border-radius: 50%;
    width: 15px;
    padding: 0px;
    height: 15px;
  }
}

.change-avatar-wrapper {
  position: absolute;
  bottom: -20px;
  right: 0;
  z-index: 10;
  max-width: 100%;
  display: flex;
  justify-content: flex-end;


  .circle-button::part(native) {
    border-radius: 50%;
    width: 40px;
    padding: 5px;
    height: 40px;
  }
}


.change-avatar-wrapper-premium {
  position: absolute;
  top: -20px;
  right: 0;
  z-index: 10;
  max-width: 100%;
  display: flex;
  justify-content: flex-end;


  .circle-button::part(native) {
    border-radius: 50%;
    width: 40px;
    padding: 5px;
    height: 40px;
  }
}

.change-backdrop-wrapper {
  position: absolute;
  top: 10px;
  right: 10px;
  max-width: 100%;
  display: flex;
  justify-content: flex-end;
}

.secondaryAlertButton {
  color: black !important
}


.user-information-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 50px;
}

.header-md::after {
  left: 0;
  bottom: -5px;
  background-position: left 0 top -2px;
  position: absolute;
  width: 100%;
  height: 0px !important;
  content: "";
}

.modal-toolbar {
  margin: 0px !important
}

.general-toolbar-ios {
  margin-top: 0px !important;
}

a.iubenda-tp-btn.iubenda-cs-preferences-link {
  @media only screen and (max-width: 768px) {
    display: none !important;
  }
}
</style>
